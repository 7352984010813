import {FormControlType, InputType, MultiSelectType, residencyCountryListLabels, ValidationRules} from "common-web";
import moment from "moment";
import store from '../../../store';
import {inquiryFormSelector} from '../../../store/selectors/axaInsuranceSelectors';
import i18next from 'i18next';

export const patientCompanionControls = [
    {
        controlType: 'group',
        key: 'companion_residency',
        class: 'form-row companion-controls',
        controls: {
            companionResidency: {
                controlType: 'control',
                hostClass: 'col-50',
                placeholder: 'form.axaForm.patient.companionFormControls.companionResidency.placeholder',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.SINGLE,
                label: "form.axaForm.patient.companionFormControls.companionResidency.label",
                touched: false,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                multiselectOptions: () => {
                    const form = inquiryFormSelector(store.getState()),
                        destinationId = form.destinationId;
                    return residencyCountryListLabels.map((country: any) => ({
                        value: country.value,
                        label: i18next.t(`residencyCountries.${country.label}`),
                        optionColor: undefined,
                        isDisabled: country.value === destinationId,
                    }))
                },
                firstOptionValue: 'Select Country',
                inputDataMapper: (data: any) => {
                    if (!data) {
                        return [];
                    }

                    return residencyCountryListLabels
                        .filter((country: any) => country.value === data)
                        .map((country: any) => ({
                            value: country.value,
                            optionColor: undefined,
                            label: i18next.t(`residencyCountries.${country.label}`),
                        }));
                },
                outputDataMapper: (data: any) => {
                    if (data === null) {
                        return null;
                    }
                    if (Array.isArray(data)) {
                        return (data.length > 0) ? data[0].value : null;
                    }
                    if (data && typeof data === 'object') {
                        return data.value;
                    }

                    return data;
                },
            },
            companionBirthDate: {
                controlType: 'control',
                defaultValue: null,
                hostClass: 'col-50',
                formControlType: FormControlType.DATE,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'form.axaForm.patient.companionFormControls.companionBirthDate.placeholder',
                label: "form.axaForm.patient.companionFormControls.companionBirthDate.label",
                disabled: false,
                maxDate: moment().format('YYYY-MM-DD'),
                openToDate: moment('01/01/2000').format('YYYY-MM-DD'),
                type: InputType.DATE,
                showMonthDropdown: true,
                showYearDropdown: true,
            }
        }
    },
    {
        controlType: 'group',
        key: 'companion_name',
        class: 'form-row',
        controls: {
            companionName: {
                controlType: 'control',
                defaultValue: null,
                hostClass: 'col-50',
                formControlType: FormControlType.INPUT,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'form.axaForm.patient.companionFormControls.companionName.placeholder',
                label: "form.axaForm.patient.companionFormControls.companionName.label",
                type: InputType.TEXT,
            },

            companionLastName: {
                controlType: 'control',
                defaultValue: null,
                hostClass: 'col-50',
                formControlType: FormControlType.INPUT,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'form.axaForm.patient.companionFormControls.companionLastName.placeholder',
                label: "form.axaForm.patient.companionFormControls.companionLastName.label",
                type: InputType.TEXT,
            }
        }
    }
];

