const en = {
    axaAdditionalInformation: {
        heading: "Medical travel insurance configurator form",
        title: "Clinic Hunter Cover",
        subtitle: "medical travel insurance",
        howItWorks: "how it works",
        aboutUs: "about us",
        terms: "terms & conditions",
        claims: "claims",
        faq: "faq",
    },
    form: {
        buttons: {
            next: "Next",
            prev: "Prev",
            buy: "Buy now",
            backToEdit: "Go back to edit",
            backToClinic: "Back to clinic hunter",
        },
        axaForm: {
            treatment: {
                title: "Treatment",
                formControls: {
                    destinationId: {
                        label: "Where are you traveling to?",
                        placeholder: 'Select Country',
                    },
                    treatmentCategoryId: {
                        label: "What treatment are you undertaking?",
                        placeholder: 'Select Treatment'
                    },
                    clinicName: {
                      label: 'What is the clinic name?',
                      placeholder: 'Type clinic name',
                    },
                    departure: {
                        label: "From",
                        groupLabel: "Your Trip Date",
                        placeholder: 'DD - MM - YYYY'
                    },
                    return: {
                        label: "To",
                        placeholder: 'DD - MM - YYYY'
                    },
                    visitDate: {
                        label: "Surgery date / First visit date",
                        placeholder: "DD - MM - YYYY"
                    }
                }
            },
            patient: {
                title: "Patient",
                subtitle: "Patient data form",
                formControls: {
                    residency: {
                        label: "What is your residency?",
                        placeholder: 'Select Country',
                    },
                    birthDate: {
                        label: "What is your date of birth?",
                        placeholder: 'DD - MM - YYYY'
                    },
                    name: {
                        label: "What is your name?",
                        placeholder: 'Name'
                    },
                    lastName: {
                        label: "What is your surname?",
                        placeholder: 'Surname'
                    },
                    city: {
                        label: "What is your address?",
                        placeholder: 'City'
                    },
                    postCode: {
                        label: "",
                        placeholder: 'Post Code'
                    },
                    street: {
                        label: "",
                        placeholder: 'Street, number'
                    },
                    email: {
                      label: "Email address",
                      placeholder: "Email address"
                    },
                    addCompanion: {
                        label: "Do you need any companions to be covered?",
                        placeholder: ''
                    },
                    companionResidency: {
                        label: `What is your companion's residency?`,
                        placeholder: 'Country'
                    },
                    companionBirthDate: {
                        label: `How old is your companion?`,
                        placeholder: 'DD - MM - YYYY'
                    },
                    companionName: {
                        label: `What is your companion's name?`,
                        placeholder: 'Companion Name'
                    },
                    companionLastName: {
                        label: `What is your companion's surname?`,
                        placeholder: 'Companion Surname'
                    }
                }
            },
            patientEmail: {
                title: "Patient's email",
                subtitle: "Please provide your patient's email address in order to send insurance details",
                formControls: {
                    patientEmail: {
                        label: "What is your Patient email",
                        placeholder: 'Email',
                    },
                    patientConfirmEmail: {
                        label: "Confirm your Patient email",
                        placeholder: "Confirm email",
                    }
                }
            },
            quote: {
                title: "Quote",
                name: {
                    label: 'Patient name'
                },
                address: {
                    label: 'Address'
                },
                birthDate: {
                    label: 'Date of birth'
                },
                destination: {
                    label: 'Destination'
                },
                treatment: {
                    label: 'Treatment'
                },
                departure: {
                    label: 'From'
                },
                return: {
                    label: 'To'
                },
                quote: {
                    label: 'Your Quote'
                },
                discountQuote: {
                    label: '10% Discount quote'
                },
                companion: {
                    label: `Companion`
                }
            },
            account: {
                title: "Account",
                registerHeading: "Create an account",
                loginHeading: "Login into account",
                subHeader: "Congratulations! Your insurance has been almost completed. Please provide us with your email address to set an account.",
                description: `<0>Why do I need an account?</0> On your account you will be able to access your policy details and make adjustments if needed.`,
            },
            payment: {
                title: "Payment",
                formControls: {
                    cardholderName: {
                        label: "Cardholder Name:",
                        placeholder: 'Cardholder name'
                    },
                    stripecard: {
                        label: 'Provide your card details',
                        placeholder: ''
                    },
                    specialTermsAndConditions: {
                        checkboxLabel: `Before concluding the insurance contract I received the <0>Special Terms and Conditions of Travel Insurance for ClinicHunter clients</0> and <1>Information Brochure Concerning Personal Data Process</1> and undertake to familiarize all insured persons with its content`
                    },
                    needsAndRequirements: {
                        checkboxLabel: `This insurance contract is compatible with my insurance needs and requirements.`
                    },
                    distanceContracts: {
                        checkboxLabel: `Before concluding the insurance contract, I read <0>the Regulations for Concluding Distance Contracts</0>.`
                    },
                    source: {
                        label: 'How did you hear about us?',
                        placeholder: 'Select',
                    },
                    customSource: {
                        label: 'Type other option',
                        placeholder: 'Type option',
                    }
                }
            },
            successPage: {
                title: "Thank you!",
                subTitle: "Your payment is waiting for the confirmation. When the process is finished, you will receive an e-mail with your insurance."
            }
        },
        clinicAxaForm: {
            treatment: {
                title: "Treatment",
                formControls: {
                    destinationId: {
                        label: "Where is your Patient traveling to?",
                        placeholder: 'Select Country',
                    },
                    treatmentCategoryId: {
                        label: "What treatment is your Patient undertaking?",
                        placeholder: 'Select Treatment'
                    },
                    clinicName: {
                        label: 'What is the clinic name?',
                        placeholder: 'Type clinic name',
                    },
                    departure: {
                        label: "From",
                        groupLabel: "Your Patient's Trip Date",
                        placeholder: 'DD - MM - YYYY'
                    },
                    return: {
                        label: "To",
                        placeholder: 'DD - MM - YYYY'
                    },
                    visitDate: {
                        label: "Surgery date / First visit date",
                        placeholder: "DD - MM - YYYY"
                    }
                }
            },
            patient: {
                title: "Patient",
                subtitle: "Patient data form",
                formControls: {
                    residency: {
                        label: "What is your Patient residency?",
                        placeholder: 'Select Country',
                    },
                    birthDate: {
                        label: "What is your Patient date of birth?",
                        placeholder: 'DD - MM - YYYY'
                    },
                    name: {
                        label: "What is your Patient name?",
                        placeholder: 'Name'
                    },
                    lastName: {
                        label: "What is your Patient surname?",
                        placeholder: 'Surname'
                    },
                    city: {
                        label: "What is your Patient address?",
                        placeholder: 'City'
                    },
                    postCode: {
                        label: "",
                        placeholder: 'Post Code'
                    },
                    street: {
                        label: "",
                        placeholder: 'Street, number'
                    },
                    addCompanion: {
                        label: "Does your Patient need any companions to be covered?",
                        placeholder: ''
                    },
                    companionResidency: {
                        label: `What is your Patient's companion's residency?`,
                        placeholder: 'Country'
                    },
                    companionBirthDate: {
                        label: `How old is your Patient's companion?`,
                        placeholder: 'DD - MM - YYYY'
                    },
                    companionName: {
                        label: `What is your Patient's companion's name?`,
                        placeholder: 'Companion Name'
                    },
                    companionLastName: {
                        label: `What is your Patient's companion's surname?`,
                        placeholder: 'Companion Surname'
                    }
                }
            },
            patientEmail: {
                title: "Patient's email",
                subtitle: "Please provide your patient's email address in order to send insurance details",
                formControls: {
                    patientEmail: {
                        label: "What is your Patient email",
                        placeholder: 'Email',
                    },
                    patientConfirmEmail: {
                        label: "Confirm your Patient email",
                        placeholder: "Confirm email",
                    }
                }
            },
            quote: {
                title: "Quote",
                name: {
                    label: 'Patient name'
                },
                address: {
                    label: 'Address'
                },
                birthDate: {
                    label: 'Date of birth'
                },
                destination: {
                    label: 'Destination'
                },
                treatment: {
                    label: 'Treatment'
                },
                departure: {
                    label: 'From'
                },
                return: {
                    label: 'To'
                },
                quote: {
                    label: 'Your Quote'
                },
                discountQuote: {
                    label: '10% Discount quote'
                },
                companion: {
                    label: `Companion`
                }
            },
            account: {
                title: "Account",
                registerHeading: "Create an account",
                loginHeading: "Login into account",
                subHeader: "Congratulations! Your insurance has been almost completed. Please provide us with your email address to set an account.",
                description: `<0>Why do I need an account?</0> On your account you will be able to access your policy details and make adjustments if needed.`,
            },
            payment: {
                title: "Payment",
                formControls: {
                    cardholderName: {
                        label: "Cardholder Name:",
                        placeholder: 'Cardholder name'
                    },
                    stripecard: {
                        label: 'Provide your card details',
                        placeholder: ''
                    },
                    specialTermsAndConditions: {
                        checkboxLabel: `Before concluding the insurance contract I received the <0>Special Terms and Conditions of Travel Insurance for ClinicHunter clients</0> and <1>Information Brochure Concerning Personal Data Process</1> and undertake to familiarize all insured persons with its content`
                    },
                    needsAndRequirements: {
                        checkboxLabel: `This insurance contract is compatible with my insurance needs and requirements.`
                    },
                    distanceContracts: {
                        checkboxLabel: `Before concluding the insurance contract, I read <0>the Regulations for Concluding Distance Contracts</0>.`
                    },
                    source: {
                        label: 'How did you hear about us?',
                        placeholder: 'Select',
                    },
                    customSource: {
                        label: 'Type other option',
                        placeholder: 'Type option',
                    }
                }
            },
            successPage: {
                title: "Thank you!",
                subTitle: "Your payment is waiting for the confirmation. When the process is finished, your patient will receive an e-mail with purchased insurance."
            }
        }
    },
    formValidation: {
        errors: {
            minLength: 'The input is too short',
            isEmailValid: 'Provide a valid email address',
            isRequired: 'The field is required',
            isCheckedTrue: 'Must be checked',
            isTrue: 'Must be checked',
            isPasswordSame: 'Passwords not match',
            isStartDateValid: 'Date must be after current day',
            isStartDateGreaterOrEvenValid: 'Date must be at or after current day',
            isEndDateValid: 'Date of return must be after departure',
            sameEmail: 'The emails should be the same',
            samePassword: 'The passwords should be the same'
        }
    },
    loginForm: {
        title: "Login",
        info: 'I already have an account',
        userName: "Login or email address",
        password: "Password",
        buttons: {
            login: "Login",
            logout: "Logout",
            dashboard: "Patient Dashboard",
            profile: "Profile",
            messages: "My messages",
            menuButtonNotLogged: "Sign in",
            menuButtonLogged: "Account Menu"
        },
        formControls: {
            loginEmail: {
                label: "Email address",
                placeholder: "Email address",
            },
            loginPassword: {
                label: "Password",
                placeholder: "Password",
            }
        }
    },
    registerUserForm: {
        title: "Registration"
    },
    registerForm: {
        title: 'I want to create one',
        buttons: {
            register: 'Register'
        },
        formControls: {
            registrationEmail: {
                placeholder: "Email address",
                label: "Email address",
            },
            registrationConfirmEmail: {
              placeholder: 'Email address',
              label: 'Confirm email address'
            },
            registrationPassword: {
                placeholder: "Password",
                label: "Password",
            },
            registrationPasswordConfirm: {
                placeholder: "Repeat password",
                label: "Confirm your password",
            },
            registrationTermsAgreement: {
                checkboxLabel: `I agree to <0>Privacy Policy</0>, <1> personal data usage</1> and <2>art. 13 GDPR</2>*`,
            },
            originOptions: {
                clinic: 'My Clinic',
                friend: 'A Friend',
                facebook: 'Facebook Group',
                google: 'Google Search',
                other: 'Other'
            }
        }
    },
    companionsForm: {
        removeButtonFirst: `remove `,
        removeButtonSecond: ` companion`,
        addButton: 'add another companion'
    },
    radioOptions: {
      yes: 'Yes',
      no: 'No',
    },
    residencyModal: {
        title: 'Dear Customers,',
        description1: 'You have navigated to our insurance offerings exclusive to EU citizens. To obtain insurance for UK citizens, please click ',
        description2: 'here',
    },
    alerts: {
        baseError: 'Something went wrong.',
        unauthorized: 'Login to see requested inquiry.',
        inquiryAlreadyPurchased: 'That inquiry has been already purchased.',
        paymentComplete: 'Payment completed. Please wait for a conformation email with insurance number.',
        paymentIsProcessing: 'Your payment is being processed. Please wait.',
        registrationSuccess: 'Welcome to Clinic Hunter Cover Medical Insurance. We have sent you an invitation email.',
        loginSuccess: 'Login success. Welcome!',
        loginFailed: 'Login failed. You have no access to the Panel. Try again or contact with our Admin.',
        logoutSuccess: 'You have been logged out.',
    },
    countries: {
        af: 'Afghanistan',
        al: 'Albania',
        dz: 'Algeria',
        ad: 'Andorra',
        ao: 'Angola',
        ag: 'Antigua and Barbuda',
        ar: 'Argentina',
        am: 'Armenia',
        au: 'Australia',
        at: 'Austria',
        az: 'Azerbaijan',
        bs: 'Bahamas',
        bh: 'Bahrain',
        bd: 'Bangladesh',
        bb: 'Barbados',
        by: 'Belarus',
        be: 'Belgium',
        bz: 'Belize',
        bj: 'Benin',
        bt: 'Bhutan',
        bo: 'Bolivia',
        ba: 'Bosnia and Herzegovina',
        bw: 'Botswana',
        br: 'Brazil',
        bn: 'Brunei',
        bg: 'Bulgaria',
        bf: 'Burkina Faso',
        bi: 'Burundi',
        cv: 'Cabo Verde',
        kh: 'Cambodia',
        cm: 'Cameroon',
        ca: 'Canada',
        cf: 'Central African Republic',
        td: 'Chad',
        cl: 'Chile',
        cn: 'China',
        co: 'Colombia',
        km: 'Comoros',
        cg: 'Congo',
        cd: 'Congo, The Democratic Republic of the',
        cr: 'Costa Rica',
        ci: 'Cote D\'Ivoire',
        hr: 'Croatia',
        cu: 'Cuba',
        cy: 'Republic of Cyprus',
        cz: 'Czech Republic',
        dk: 'Denmark',
        dj: 'Djibouti',
        dm: 'Dominica',
        do: 'Dominican Republic',
        ec: 'Ecuador',
        eg: 'Egypt',
        sv: 'El Salvador',
        gq: 'Equatorial Guinea',
        er: 'Eritrea',
        ee: 'Estonia',
        sz: 'Eswatini',
        et: 'Ethiopia',
        fj: 'Fiji',
        fi: 'Finland',
        fr: 'France',
        ga: 'Gabon',
        gm: 'Gambia',
        ge: 'Georgia',
        de: 'Germany',
        gh: 'Ghana',
        gr: 'Greece',
        gd: 'Grenada',
        gt: 'Guatemala',
        gn: 'Guinea',
        gw: 'Guinea-Bissau',
        gy: 'Guyana',
        ht: 'Haiti',
        va: 'Holy See (Vatican City State)',
        hn: 'Honduras',
        hu: 'Hungary',
        is: 'Iceland',
        in: 'India',
        id: 'Indonesia',
        ir: 'Iran, Islamic Republic Of',
        iq: 'Iraq',
        ie: 'Ireland',
        il: 'Israel',
        it: 'Italy',
        jm: 'Jamaica',
        jp: 'Japan',
        jo: 'Jordan',
        kz: 'Kazakhstan',
        ke: 'Kenya',
        ki: 'Kiribati',
        kp: 'Korea, Democratic People\'S Republic of',
        kr: 'Korea, Republic of',
        kw: 'Kuwait',
        kg: 'Kyrgyzstan',
        la: 'Lao People\'S Democratic Republic',
        lv: 'Latvia',
        lb: 'Lebanon',
        ls: 'Lesotho',
        lr: 'Liberia',
        ly: 'Libya',
        li: 'Liechtenstein',
        lt: 'Lithuania',
        lu: 'Luxembourg',
        mg: 'Madagascar',
        mw: 'Malawi',
        my: 'Malaysia',
        mv: 'Maldives',
        ml: 'Mali',
        mt: 'Malta',
        mh: 'Marshall Islands',
        mr: 'Mauritania',
        mu: 'Mauritius',
        mx: 'Mexico',
        fm: 'Micronesia',
        md: 'Moldova, Republic of',
        mc: 'Monaco',
        mn: 'Mongolia',
        me: 'Montenegro',
        ma: 'Morocco',
        mz: 'Mozambique',
        mm: 'Myanmar',
        na: 'Namibia',
        nr: 'Nauru',
        np: 'Nepal',
        nl: 'Netherlands',
        nz: 'New Zealand',
        ni: 'Nicaragua',
        ne: 'Niger',
        ng: 'Nigeria',
        mk: 'North Macedonia',
        no: 'Norway',
        om: 'Oman',
        pk: 'Pakistan',
        pw: 'Palau',
        pa: 'Panama',
        pg: 'Papua New Guinea',
        py: 'Paraguay',
        pe: 'Peru',
        ph: 'Philippines',
        pl: 'Poland',
        pt: 'Portugal',
        qa: 'Qatar',
        ro: 'Romania',
        ru: 'Russian Federation',
        rw: 'Rwanda',
        kn: 'Saint Kitts and Nevis',
        lc: 'Saint Lucia',
        ws: 'Samoa',
        sm: 'San Marino',
        st: 'Sao Tome and Principe',
        sa: 'Saudi Arabia',
        sn: 'Senegal',
        rs: 'Serbia',
        sc: 'Seychelles',
        sl: 'Sierra Leone',
        sg: 'Singapore',
        sk: 'Slovakia',
        si: 'Slovenia',
        sb: 'Solomon Islands',
        so: 'Somalia',
        za: 'South Africa',
        ss: 'South Sudan',
        es: 'Spain',
        lk: 'Sri Lanka',
        vc: 'St. Vincent & Grenadines',
        ps: 'State of Palestine',
        sd: 'Sudan',
        sr: 'Suriname',
        se: 'Sweden',
        ch: 'Switzerland',
        sy: 'Syria',
        tj: 'Tajikistan',
        tz: 'Tanzania',
        th: 'Thailand',
        tl: 'Timor-Leste',
        tg: 'Togo',
        to: 'Tonga',
        tt: 'Trinidad and Tobago',
        tn: 'Tunisia',
        tr: 'Turkey',
        tm: 'Turkmenistan',
        tv: 'Tuvalu',
        ug: 'Uganda',
        ua: 'Ukraine',
        ae: 'United Arab Emirates',
        gb: 'United Kingdom',
        us: 'United States',
        uy: 'Uruguay',
        uz: 'Uzbekistan',
        vu: 'Vanuatu',
        ve: 'Venezuela',
        vn: 'Vietnam',
        ye: 'Yemen',
        zm: 'Zambia',
        zw: 'Zimbabwe',
        xk: 'Kosovo',
    },
    residencyCountries: {
        at: 'Austria',
        be: 'Belgium',
        bg: 'Bulgaria',
        hr: 'Croatia',
        cy: 'Cyprus',
        cz: 'Czech Republic',
        dk: 'Denmark',
        ee: 'Estonia',
        fi: 'Finland',
        fr: 'France',
        de: 'Germany',
        gr: 'Greece',
        hu: 'Hungary',
        ie: 'Ireland',
        is: 'Island',
        it: 'Italy',
        lv: 'Latvia',
        li: 'Liechtenstein',
        lt: 'Lithuania',
        lu: 'Luxembourg',
        mt: 'Malta',
        mc: 'Monaco',
        nl: 'Netherlands',
        no: 'Norway',
        pl: 'Poland',
        pt: 'Portugal',
        ro: 'Romania',
        sm: 'San Marino',
        sk: 'Slovakia',
        si: 'Slovenia',
        ch: 'Switzerland',
        es: 'Spain',
        se: 'Sweden',
        gb: 'United Kingdom',
        va: 'Vatican City'
    },
};

export default en;
